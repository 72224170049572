<template>
  <div
    class="flex flex-row text-regular-18 !tracking-[5.4px] iq-hover hover:cursor-pointer z-50 lg:mb-1.5"
    @click="onClickCredit()"
  >
    <span class="!text-iq-red">C</span>{{ credits }}
  </div>
</template>

<script setup lang="ts">
const organizationStore = useOrganizationStore();

const credits = computed(() => {
  const subscription = organizationStore.subscription;
  const credits = subscription?.credits ?? 0;
  const creditsExtra = subscription?.creditsExtra ?? 0;
  const creditsReserved = subscription?.creditsReserved ?? 0;

  return credits + creditsExtra - creditsReserved;
});

async function onClickCredit() {
  return await navigateTo('/credits');
}
</script>
